import Vue from 'vue'

// To Kg Filter
Vue.filter('toKg', value => {
  if (!value) {
    return null
  }

  if (typeof value !== 'number') {
    value = parseFloat(value)
  }

  return Number.isInteger(value) ? value : value.toFixed(3)
})
