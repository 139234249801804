export default {
  showLoader (state, loaderText) {
    state.overlay = true
    state.loader = true
    state.loaderText = loaderText
  },
  hideLoader (state) {
    state.overlay = false
    state.loader = false
    state.loaderText = ''
  },
  setLayout (state, layout) {
    state.layout = layout
  }
}
