export default {
  setUser (state, user) {
    state.user = user
  },
  setAccessToken (state, token) {
    localStorage.setItem('access_token', token)
    state.access_token = token
  },
  resetUser (state) {
    state.user = ''
    state.access_token = ''
    localStorage.removeItem('access_token')
  },
}
