import request from '@/server/request'

export default {
  async login({ dispatch }, credentials) {
    return request({ entity: 'customer', action: 'login' }, null, 'POST', credentials)
      .then(async credentials => {
        if (credentials.token_type === 'Bearer' && credentials.access_token) {
          await dispatch('loadUserData', credentials.access_token)
          .then(user => {
            if (user.id) {
              dispatch('setAccessToken', credentials.access_token)
            }
          })
        }
      })
  },

  async register({ dispatch }, userData) {
    return request({ entity: 'customer', action: 'register' }, null, 'POST', userData)
    .then(async credentials => {
      if (credentials.token_type === 'Bearer' && credentials.access_token) {
        await dispatch('loadUserData', credentials.access_token)
        .then(user => {
          if (user.id) {
            dispatch('setAccessToken', credentials.access_token)
            return credentials.access_token
          }
        })
      }
    })
  },

  async loadUserData({ commit }, token) {
    return request({ entity: 'customer', action: 'me' }, token, 'GET')
      .then(user => {
        commit('setUser', user)
        return user
      })
  },

  setAccessToken({ commit }, token) {
    commit('setAccessToken', token)
  }
}
