import request from '@/server/request'

export default {
  load (context, { token, option_id }) {
    return request(
      { entity: 'catalog.product.options', action: 'get' },
      token,
      'GET',
      { option_id }
    )
  },
  getProductOptions (context, { token, params }) {
    return request({ entity: 'catalog.product.options.list', action: 'get' }, token, 'GET', { ...params })
  },
  update (context, { token, option, option_id }) {
    return request(
      { entity: 'catalog.product.options', action: 'update' },
      token,
      'POST',
      { option_id, option }
    )
  },
  create (context, { token, option }) {
    return request({ entity: 'catalog.product.options', action: 'create' }, token, 'PUT', { option })
  },
  createType (context, { token, type, option_id }) {
    return request(
      { entity: 'catalog.product.options.types', action: 'create' },
      token,
      'PUT',
      { option_id, type }
    )
  },
  updateType (context, { token, type, type_id, option_id }) {
    return request(
      { entity: 'catalog.product.options.types', action: 'update' },
      token,
      'POST',
      { type_id, option_id, type }
    )
  },
  remove (context, { token, option_id }) {
    return request(
      { entity: 'catalog.product.options', action: 'remove' },
      token,
      'DELETE',
      { option_id }
    )
  },
  removeType (context, { token, type_id, option_id }) {
    return request(
      { entity: 'catalog.product.options.types', action: 'remove' },
      token,
      'DELETE',
      { type_id, option_id }
    )
  }
}
