<template>
  <v-layout class="wrap">
    <app-header :menuShow="menuShow" @changesValueMenu="changesValueMenu"/>
    <v-main>
      <v-flex class="flex-block h-100" xs12 sm12 md12>
        <navigation :menuShow="menuShow" @changesValueMenu="changesValueMenu" dmd2/>
        <loader/>
      <router-view md10  class="w-100 p10"/>
      </v-flex>
    </v-main>
  </v-layout>
</template>

<script>
import Header from '@/components/Header'
import Navigation from '@/components/Navigation'
import Loader from '@/components/Loader.vue';

export default {
  name: 'AppPayout',
  components: {
    Loader,
    'app-header': Header,
    Navigation
  },
  data () {
    return {
      menuShow: true,
      windowWidth: 0,
    }
  },
  mounted () {
    this.$nextTick(() => {
      this.handleResize()
      window.addEventListener('resize', this.handleResize)
    })
    console.log('app layout mounted')
  },
  methods: {
    changesValueMenu (value) {
      this.menuShow = value
    },
    handleResize () {
      if (this.windowWidth !== window.innerWidth) {
        this.windowWidth = window.innerWidth
        this.menuShow = this.windowWidth > 1300
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.handleResize)
  }
}
</script>
<style scoped lang="scss">
  .flex-block {
    display: flex;
  }
  .w-100 {
    width: 100%;
  }
  .h-100 {
    height: 100%;
  }
  .p10 {
    padding: 10px;
  }
</style>
