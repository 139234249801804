import state from './state'
import getters from './getters'
import mutations from './mutations'
import actions from './actions'

import category from '@/store/catalog/category'
import product from '@/store/catalog/product'
import product_option from '@/store/catalog/product_option'

export default {
  modules: {
    category, // catalog/category
    product, // catalog/product
    product_option, // catalog/product_option
  },
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}
