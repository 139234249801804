<template>
  <div class="loader-container fixed" v-if="isVisible">
    <div class="loader-inner-container fixed">
      <div class="spinner relative">
        <span class="loader"/>
      </div>
      <div
        class="loader-message-container mt15 py5 px15 align-center h6 cl-white"
        v-if="message"
      >
        {{ message }}
      </div>
    </div>
  </div>
</template>

<script lang="ts">
export default {
  name: 'Loader',
  data () {
    return {
      message: '',
      isVisible: false
    }
  },
  methods: {
    show (message = '') {
      this.message = message
      this.isVisible = true
    },
    hide () {
      this.isVisible = false
    }
  },
  beforeMount () {
    this.$bus.$on('notification-progress-start', this.show)
    this.$bus.$on('notification-progress-stop', this.hide)
  },
  beforeDestroy () {
    this.$bus.$off('notification-progress-start', this.show)
    this.$bus.$off('notification-progress-stop', this.hide)
  }
}
</script>

<style lang="scss" scoped>
@import '~/src/css/base/global_vars';
@import '~/src/css/variables/colors';
@import '~/src/css/helpers/functions/color';
$color-container-bg: color(black);
$color-message-bg: color(success);
$z-index-loader: map-get($z-index, loader);

.loader-container {
  z-index: $z-index-loader;
  top: 0;
  left: 0;
  bottom: 0;
  right: 0;
  background-color: rgba($color-container-bg, 0.65);
}

.loader-inner-container {
  left: 50%;
  top: 50%;
  transform: translateY(-50%) translateX(-50%);
}

.loader-message-container {
  background-color: rgba($color-message-bg, 0.75);
  border-radius: 50px;
  letter-spacing: 0.5px;
}

.loader {
  width: 48px;
  height: 48px;
  border-radius: 50%;
  position: relative;
  animation: rotate 1s linear infinite;
  display: block;
}
.loader::before , .loader::after {
  content: "";
  box-sizing: border-box;
  position: absolute;
  inset: 0px;
  border-radius: 50%;
  border: 5px solid #FFF;
  animation: prixClipFix 2s linear infinite ;
}
.loader::after{
  transform: rotate3d(90, 90, 0, 180deg );
  border-color: #FF3D00;
}

@keyframes rotate {
  0%   {transform: rotate(0deg)}
  100%   {transform: rotate(360deg)}
}

@keyframes prixClipFix {
  0%   {clip-path:polygon(50% 50%,0 0,0 0,0 0,0 0,0 0)}
  50%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 0,100% 0,100% 0)}
  75%, 100%  {clip-path:polygon(50% 50%,0 0,100% 0,100% 100%,100% 100%,100% 100%)}
}
.fixed {
  position: fixed;
}
</style>
