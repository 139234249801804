export default {
  showLoader ({ commit }, loaderText) {
    commit('showLoader', loaderText)
  },
  hideLoader ({ commit }) {
    commit('hideLoader')
  },
  setLayout ({ commit }, layout) {
    commit('setLayout', layout)
  }
}
