import Vue from 'vue';
import Vuex from 'vuex';

import user from './store/user';
import ui from './store/ui';
import catalog from './store/catalog';
import notification from './store/notification';
import dashboard from "@/store/dashboard";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    user,
    ui,
    catalog,
    notification,
    dashboard,
  },
  state: {},
  mutations: {},
  getters: {},
  actions: {}
})
