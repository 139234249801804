import router from "@/router";

export default function auth ({ next }) {
  if(router.history.pending.query.token) {
    localStorage.setItem('access_token', router.history.pending.query.token)
  }
  if (!localStorage.getItem('access_token')) {
    return next({ name: 'login' })
  }
  return next()
}
