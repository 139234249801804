import Vue from 'vue'

Vue.filter('toAddress', value => {
  if (!value) {
    console.error('Address does not exist')
    return ''
  }
  if (typeof value.address1 !== 'string') {
    console.error('Bad Address format.')
    return value
  }

  return value.address1 + ',\n' + value.city
})
