import request, { buildServerUrl } from '@/server/request'

export default {
  loadTree({ commit }, token) {
    return request({ entity: 'catalog.category.tree', action: 'get' }, token, 'GET')
      .then(result => {
        commit('setTree', result)
      })
  },
  loadCategory(context, { category_id, token }) {
    return request({ entity: 'catalog.category', action: 'get' }, token, 'GET', { category_id })
  },
  loadCategoryProducts({ commit }, { category_id, token, params }) {
    commit('setCategoryProductsLoading', { category_id, loading: true })
    return request({ entity: 'catalog.category.products', action: 'get' }, token, 'GET', { category_id, ...params })
      .then(result => {
        let productsWithEmptyPositions = result.data.data.filter(product => Number(product.position) === 0),
          flag = (productsWithEmptyPositions && productsWithEmptyPositions.length > 1)
        commit('setEmptyPositionsFlag', flag)
        commit('setCategoryProducts', { category_id, products: result.data.data })
        return result
      })
      .finally(() => {
        commit('setCategoryProductsLoading', { category_id, loading: false })
      })
  },
  addCategoryProduct({ commit }, { category_id, product }) {
    commit('addProduct', { category_id, product })
  },
  updateProductData({ commit }, { category_id, product_id, product }) {
    commit('updateProductData', { category_id, product_id, product })
  },
  save({ dispatch,commit }, { token, category_id, category }) {
    return request({ entity: 'catalog.category', action: 'update' }, token, 'PATCH', { category_id, ...category })
      .then(result => {
        dispatch('loadTree', token)
        commit('updateCategory', result.category)
        return result
      })
  },
  create({ dispatch, commit }, { token, category }) {
    return request({ entity: 'catalog.category', action: 'create' }, token, 'POST', { ...category })
      .then(result => {
        dispatch('loadTree', token)
        commit('addCategory', result)
        return result
      })
  },
  deleteCategory({ dispatch, commit }, { token, category_id }) {
    return request({ entity: 'catalog.category', action: 'delete' }, token, 'DELETE', { category_id })
      .then(result => {
        dispatch('loadTree', token)
        commit('deleteCategory', result)
      })
  },
  uploadImage (context, { image, categoryId, token }) {
    let link =  buildServerUrl('catalog.category', 'upload_image').replace('{{category_id}}', categoryId)
    let form = new FormData()
    form.append(`file[]`, image, image.name)
    return fetch(link, {
      method: 'POST',
      headers:{
        'Authorization': `Bearer ${token}`
      },
      body: form
    })
  },
  setEmptyPositionsFlag ({ commit }, payload) {
    commit('setEmptyPositionsFlag', payload)
  },
  removeImage (context, { token, category_id }) {
    return request(
      { entity: 'catalog.category', action: 'remove_image' },
      token,
      'DELETE',
      { category_id }
    )
  },
}
