<template>
  <v-app>
    <notification/>
    <component :is="layout" :class="layout" />
  </v-app>
</template>

<script>
import AuthLayout from '@/layouts/AuthLayout'
import AppLayout from '@/layouts/AppLayout'
import Notification from "@/pages/Notification";
import { mapActions, mapGetters } from 'vuex'

export default {
  name: 'App',
  components: {
    AuthLayout,
    AppLayout,
    Notification
  },
  beforeMount() {
    document.documentElement.style.overflowY = 'auto';
  },
  async mounted() {
    if (localStorage.getItem('access_token')) {
      await this.setAccessToken(localStorage.getItem('access_token'))
      await this.loadUserData(localStorage.getItem('access_token'))
    }
    let language = ''
    if (this.$route.query && this.$route.query.lang) {
      language = this.$route.query.lang === 'en'? 'en-US': 'uk-UA'
    } else {
      language = localStorage.getItem('language')
    }
    if (!language) {
      language = 'uk-UA'
    }
    localStorage.setItem('language', language)
    this.$i18n.locale = language
    this.$i18n.fallbackLocale = language
  },
  computed: {
    ...mapGetters({
      overlay: 'ui/getOverlay',
      loader: 'ui/getLoader',
      loaderText: 'ui/getLoaderText',
      currentUser: 'user/getUser'
    }),
    layout () {
      return this.currentUser && this.currentUser.id ? 'app-layout' : 'auth-layout'
    }
  },
  methods: {
    ...mapActions({
      setAccessToken: 'user/setAccessToken',
      loadUserData: 'user/loadUserData'
    })
  }
}
</script>


