import request, { buildServerUrl } from '@/server/request'
import * as config from '../../../config/default.json'

export default {
  load (context, { token, product_id }) {
    return request(
      { entity: 'catalog.product', action: 'get' },
      token,
      'GET',
      { product_id }
    )
  },
  getProducts (context, { token, params }) {
    return request({ entity: 'catalog.product.list', action: 'get' }, token, 'GET', { ...params })
  },
  loadProductOptions (context, { token, product_id }) {
    return request(
      { entity: 'catalog.product', action: 'get_options' },
      token,
      'GET',
      { product_id }
    )
  },
  loadProductCategories (context, { token, product_id }) {
    return request(
      { entity: 'catalog.product', action: 'get_categories' },
      token,
      'GET',
      { product_id }
    )
  },
  loadProductImages (context, { token, product_id }) {
    return request(
      { entity: 'catalog.product', action: 'get_images' },
      token,
      'GET',
      { product_id }
    )
  },
  removeProductImage (context, { token, product_id, image_id }) {
    return request(
      { entity: 'catalog.product', action: 'remove_image' },
      token,
      'DELETE',
      { product_id, image_id }
    )
  },
  update (context, { token, product, product_id }) {
    return request(
      { entity: 'catalog.product', action: 'update' },
      token,
      'POST',
      { product_id, product }
    )
  },
  create (context, { token, product }) {
    return request({ entity: 'catalog.product', action: 'create' }, token, 'PUT', { product })
  },
  importProducts (context, { token, file, type }) {
    let form = new FormData()
    form.append('csv_file', file, file.name)
    let link = config.catalog.product.import
    link = link.replace('{{type}}', type)
    return fetch(link, {
      method: 'POST',
      body: form,
      headers:{
        'Authorization': `Bearer ${token}`
      }
    }).then(response => response.json())
  },
  exportProductsCsv ( context, { token, type}) {
    let link = config.catalog.product.export
    link = link.replace('{{type}}', type)
    let filename = ''
    return fetch(link, {
      headers:{
        'Authorization': `Bearer ${token}`,
        'Content-Type': 'text/csv'
      }
    }).then(response => {
      filename = response.headers.get('Content-Disposition');
      return response.blob()
    })
    .then(res => {
      let element = document.createElement('a');
      let link = window.URL.createObjectURL(res);
      element.href = link;
      element.download = filename;
      element.click();
      element.remove();
      window.URL.revokeObjectURL(link);
      return link;
    })
  },
  uploadImages (context, { images, productId, token }) {
    let link =  buildServerUrl('catalog.product', 'upload_image').replace('{{product_id}}', productId)
    let form = new FormData()
    images.forEach((image, ind) => {
      form.append(`file[${ind}]`, image, image.name)
    })
    return fetch(link, {
      method: 'POST',
      headers:{
        'Authorization': `Bearer ${token}`
      },
      body: form
    })
  }
  // updateProductData({ commit }, { product_id, product }) {
  //
  // }
}
