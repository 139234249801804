import Vue from 'vue'

// To UAH Filter
Vue.filter('toUAH', value => {
  if (typeof value !== 'number') {
    value = parseFloat(value)
  }

  return value.toLocaleString('ua-UA', {
    currency: 'UAH',
    maximumFractionDigits: 2,
    style: 'currency'
  })
})
