import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: '#f5a623',
        secondary: '#f5f5f5',
        success: '#4db6ac',
        error: '#d32f2f'
      },
    },
  }
});
