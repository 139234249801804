import request from '@/server/request'
import rootStore from '@/store'
import i18n from '@/plugins/i18n'

export default {
  async login({ dispatch }, credentials) {
    return request({ entity: 'user', action: 'login' }, null, 'POST', credentials)
      .then(async credentials => {
        if (credentials.token_type === 'Bearer' && credentials.access_token) {
          await dispatch('loadUserData', credentials.access_token)
          .then(user => {
            if (user.id) {
              dispatch('setAccessToken', credentials.access_token)
            }
          })
        } else if (credentials.message) {
          rootStore.dispatch('notification/spawnNotification', {
            type: 'error',
            message: i18n.t(credentials.message),
            action1: { label: i18n.t('OK') }
          })
        }
      })
  },

  async requestPasswordReset(context, email) {
    return request({ entity: 'user', action: 'request_password_reset' }, null, 'POST', { email })
  },

  async resetPassword(context, payload) {
    return request({ entity: 'user', action: 'reset_password' }, null, 'POST', payload)
  },

  async register({ dispatch }, userData) {
    return request({ entity: 'user', action: 'register' }, null, 'POST', userData)
    .then(async credentials => {
      if (credentials.token_type === 'Bearer' && credentials.access_token) {
        await dispatch('loadUserData', credentials.access_token)
        .then(user => {
          if (user.id) {
            dispatch('setAccessToken', credentials.access_token)
            return credentials.access_token
          }
        })
      }
    })
  },

  async loadUserData({ commit }, token) {
    return request({ entity: 'user', action: 'me' }, token, 'GET')
      .then(user => {
        commit('setUser', user)
        return user
      })
  },

  setAccessToken({ commit }, token) {
    commit('setAccessToken', token)
  },

  logout({ commit }) {
    commit('resetUser');

  }
}
