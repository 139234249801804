export default {
  setTree (state, tree) {
    state.tree = tree
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setEmptyPositionsFlag(state, flag) {
    state.emptyPositionsFlag = flag
  },
  addCategory (state, category) {
    state.categories.push(category)
  },
  updateCategory (state, category) {
    let categoryIndex = state.categories.findIndex(c => c.entity_id === category.entity_id)
    if (categoryIndex) {
      state.categories.splice(categoryIndex, 1, category)
    }
  },
  deleteCategory (state, category_id) {
    let categoryIndex = state.categories.findIndex(c => c.entity_id === category_id)
    if (categoryIndex) {
      state.categories.splice(categoryIndex, 1)
    }
  },
  setCategoryProductsLoading (state, { category_id, loading }) {
    let categoryProducts = state.category_products.find(cp => cp.category_id === category_id)
    if (categoryProducts) {
      categoryProducts.loading = loading
    } else {
      state.category_products.push({ category_id, products: [], loading })
    }
  },
  setCategoryProducts (state, { category_id, products }) {
    let categoryProducts = state.category_products.find(cp => cp.category_id === category_id)
    if (categoryProducts) {
      categoryProducts.products = products
    } else {
      state.category_products.push({ category_id, products, loading: false })
    }
  },
  updateProductData (state, { category_id, product_id, product }) {
    let categoryProducts = state.category_products.find(cp => cp.category_id === category_id)
    if (categoryProducts) {
      let productIdx = categoryProducts.products.findIndex(p => p.entity_id === product_id)
      categoryProducts.products.splice(productIdx, 1, product)
    } else {
      state.category_products.push({ category_id, product: [ product ], loading: false })
    }
  },
  addProduct(state, { category_id, product }) {
    let categoryProducts = state.category_products.find(cp => cp.category_id === category_id)
    if (categoryProducts) {
      categoryProducts.products.push(product)
    } else {
      state.category_products.push({ category_id, product: [ product ], loading: false })
    }
  }
}
