import request from '@/server/request';

export default {
  loadOrderAmounts ({ commit }, { token, date_from, date_to }) {
    return request({ entity: 'dashboard.orders_amounts', action: 'get' }, token, 'GET', { date_from, date_to })
      .then(result => {
        commit('setOrdersData', result.data);
        return result;
      });
  },

  loadBestsellers ({ commit }, { token, date_from, date_to }) {
    return request({ entity: 'dashboard.bestsellers', action: 'get' }, token, 'GET', { date_from, date_to })
      .then(result => {
        commit('setBestsellers', result.data);
        return result;
      });
  }
};
