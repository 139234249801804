import Vue from 'vue'

// To USD Filter
Vue.filter('toUSD', value => {
  if (value) {
    if (typeof value !== 'number') {
      value = parseFloat(value)
    }

    return value.toLocaleString('en-US', {
      currency: 'USD',
      maximumFractionDigits: 2,
      style: 'currency'
    })
  }
})
