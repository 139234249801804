<template>
  <v-container fluid fill-height>
    <v-layout align-center justify-center>
      <router-view />
    </v-layout>
  </v-container>
</template>

<script>

export default {
  name: 'AuthLayout'
}
</script>


