export default {
  setTree (state, tree) {
    state.tree = tree
  },
  setCategories (state, categories) {
    state.categories = categories
  },
  setCategoryProductsLoading (state, { category_id, loading }) {
    let categoryProducts = state.category_products.find(cp => cp.category_id === category_id)
    if (categoryProducts) {
      categoryProducts.loading = loading
    } else {
      state.category_products.push({ category_id, products: [], loading })
    }
  },
  setCategoryProducts (state, { category_id, products }) {
    let categoryProducts = state.category_products.find(cp => cp.category_id === category_id)
    if (categoryProducts) {
      categoryProducts.products = products
    } else {
      state.category_products.push({ category_id, products, loading: false })
    }
  }
}
