export default {
  getTree: state => state.tree,
  getCategories: state => state.categories,
  isProductPositionsInitRequired: state => state.emptyPositionsFlag,
  getCategoryProducts (state) {
    return categoryId => {
      let categoryProducts = state.category_products.find(cp => cp.category_id === categoryId);
      return categoryProducts ? categoryProducts.products : [];
    };
  },
  getCategoryProductsLoading (state) {
    return categoryId => {
      let categoryProducts = state.category_products.find(cp => cp.category_id === categoryId);
      return categoryProducts ? categoryProducts.loading : false;
    };
  }
};
